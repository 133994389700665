/* eslint-disable */
const ListAdvertising = {
  page_news: {
    text: "ข่าวสาร",
    title: "ข่าวสาร คืออะไร",
    detail: "ข่าวสาร คือการแจ้งข้อมูลข่าวสารที่ทางโครงการอยากประชาสัมพันธ์ให้ลูกบ้านได้รับข่าวสารอย่าง ทั่วถึงและพร้อมเพียงกัน ทำให้ทางโครงการสะดวกสบายมากยิ่งขึ้นลดขั้นตอนการทำได้มากขึ้น",
    title_benefit: "ประโยชน์ที่ลูกบ้านจะได้รับ",
    detail_benefit: "คือ การได้รับข่าวสารที่ถูกต้องและมีความเข้าใจที่ตรงกันกับทางโครงการและเพื่อนบ้านคนอื่นๆ และสามารถคอยเช็คข้อมูลข่าวสารของโครงการลูกบ้าน โดยผ่านทางเว็บไซต์ของลูกบ้านบนหน้าจอมือถือของลูกบ้าน"
  },
  page_close_home: {
    text: "เปิดปิดบ้าน",
    title: "เปิดปิดบ้าน คืออะไร",
    detail: "เปิดปิดบ้าน คือการที่ลูกบ้านแจ้งความประสงค์ว่าต้องการที่จะปิดบ้านไม่สะดวกรับแขกในวันที่ปิดบ้านหากลูกบ้านได้แจ้งปิดบ้าน เมื่อมีแขกมาขอพบลูกบ้าน รปภ ตรวจสอบข้อมูลก็จะทราบว่าลูกบ้านไม่สะดวกรับแขกในวันที่แจ้งปิดบ้าน",
    title_benefit: "ประโยชน์ที่ลูกบ้านจะได้รับ",
    detail_benefit: "คือ เพิ่มความสะดวกสบายให้ลูกบ้าน สามารถแจ้งจุดประสงค์ในวันที่ลูกบ้านไม่สะดวกรับแขกลูกบ้านสามารถแจ้งวันเปิดปิดบ้านผ่านเว็บไซต์บนหน้าจอมือถือของลูกบ้าน"
  },
  page_vip: {
    text: "VIP",
    title: "VIP คืออะไร",
    detail: "VIP คือการแจ้งแขกพิเศษของลูกบ้าน เมื่อลูกบ้านได้ทำการแจ้งแขก VIP ไว้ในระบบ หากมีแขก VIP มาเข้าพบลูกบ้าน รปภ ตรวจสอบข้อมูลระบบจะทำการแจ้งเตือน รปภ ว่ารถหมายเลขทะเบียนนี้เป็นแขก VIP ของลูกบ้าน ทำให้สะดวกสบายมากขึ้นในการทำงาน ลดขั้นตอนในการตรวจสอบที่ยุ่งยากลง",
    title_benefit: "ประโยชน์ที่ลูกบ้านจะได้รับ",
    detail_benefit: "คือ ความสะดวกสบายในการแจ้งข้อมูล ลูกบ้านสามารถตรวจสอบข้อมูลว่ามีใครบ้างที่เข้ามาขอพบลูกบ้าน โดยตรวจสอบผ่านเว็บไซต์ของลูกบ้านบนหน้าจอมือถือของลูกบ้านได้เลย ไม่ว่าลูกบ้านจะอยู่ที่ไหนก็สามารถตรวจสอบได้ว่ามีใครมาขอพบลูกบ้านบ้าง"
  },
  page_request_help: {
    text: "เรียกใช้บริการ",
    title: "เรียกใช้บริการ คืออะไร",
    detail: "เรียกใช้บริการ คือการเรียกใช้ขอความช่วยเหลือจากทาง รปภ ตัวอย่างเช่นขอให้ รปภ เรียกรถแท็กซี่ เรียกรถพยาบาล หรือ ให้ช่วยในด้านอื่นๆที่ลูกบ้านต้องการความช่วยเหลือ เมื่อลูกบ้านแจ้งเรียกใช้ บริการ ระบบจะทำการแจ้งเตือนมายัง รปภ",
    title_benefit: "ประโยชน์ที่ลูกบ้านจะได้รับ",
    detail_benefit: "คือ ลูกบ้านสามารถเรียกใช้บริการผ่านเว็บไซต์บนหน้าจอมือถือของลูกบ้าน ทำให้สะดวกสบายมากยิ่งขึ้น สามารถแจ้งวัตถุประสงค์ที่ต้องการจะเรียกใช้บริการผ่านเว็บไซต์"
  }, 
  page_enable_electronic_stamp: {
    text: "ตราประทับอิเล็กเทอนิกส์",
    title: "ตราประทับอิเล็กเทอนิกส์ คืออะไร",
    detail: "ตราประทับอิเล็กเทอนิกส์ คือการประทับตราให้กับแขกลูกบ้านที่เข้ามาพบลูกบ้าน โดยเปลี่ยนจากการใช้ตราประทับแบบหมึก ให้เปลี่ยนมาใช้การประทับตราในระบบเว็บไซต์ เพื่อความสะดวกสบายและทันสมัยมากยิ่งขึ้น",
    title_benefit: "ประโยชน์ที่ลูกบ้านจะได้รับ",
    detail_benefit: "คือ ได้รับความสะดวกสบายในการประทับตราอิเล็กเทอนิกส์ โดยการประทับตราอิเล็กเทอนิกส์ผ่านเว็บไซต์บนหน้าจอมือถือของลูกบ้าน หากลูกบ้านเผลอลืมกดตราประทับอิเล็กเทอนิกส์ให้กับแขกลูกบ้าน ลูกบ้านสามารถกดตราประทับผ่านเว็บไซต์บนหน้าจอมือถือลูกบ้านได้ทันที"
  }
};
export default ListAdvertising
/* eslint-enable */