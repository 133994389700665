<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/1 md:w-1/1 mb-base">
        <vx-card :title="`โครงการนี้ไม่ได้เปิดใช้งานส่วนเสริม '${text}' หากท่านต้องการเปิดใช้งานกรุณาติดต่อ`">
          <ul class="vx-timeline">
            <li>
              <div class="timeline-icon" :class="`bg-warning`">
                <feather-icon
                  :icon="`AlertCircleIcon`"
                  svgClasses="text-white stroke-current w-5 h-5"
                />
              </div>
              <div class="timeline-info">
                <h4 class="font-semibold">{{ title }}</h4>
                <p><span style="font-size:16px" class="activity-desc">{{ detail }}</span></p>
              </div>
            </li>
            <li>
              <div class="timeline-icon" :class="`bg-success`">
                <feather-icon
                  :icon="`CheckIcon`"
                  svgClasses="text-white stroke-current w-5 h-5"
                />
              </div>
              <div class="timeline-info">
                <h5 class="font-semibold">{{ title_benefit }}</h5>
                <p><span style="font-size:16px" class="activity-desc">{{ detail_benefit }}</span></p>
              </div>
            </li>
          </ul>
          <br>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center">
              <vs-button href="https://forms.gle/Peey8eNiLiaJWT838" target="_blank" color="success" type="gradient" icon-pack="feather" icon="icon-check" icon-after>
                ขอเปิดใช้งานส่วนเสริม
              </vs-button>
            </vs-col>
          </vs-row>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  name: "the-advertising",
  props: ["postDetails"],
  data() {
    return {
      text: "",
      title: "",
      detail: "",
      title_benefit: "",
      detail_benefit: ""
    };
  },
  created() {
    this.text = this.postDetails.text;
    this.title = this.postDetails.title;
    this.detail = this.postDetails.detail;
    this.title_benefit = this.postDetails.title_benefit;
    this.detail_benefit = this.postDetails.detail_benefit;
  }
};
</script>
<style scoped>
  p {
    font-size: 20px;
  }
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";
</style>
