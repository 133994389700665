<template>
  <vs-divider position="left">
    <br/>
  <div class="vs-list--title">โครงการ {{customerName}} บ้านเลขที่ {{getDisplayAddress}}</div>
</vs-divider>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";


Vue.use(VueCookies);

export default {
  name: 'home-header',
  data() {
    return {
      home: "",
      customerName: "",
      houseInfo: null,
    };
  },
  beforeCreate() {
  },
  props: {
    // navbarColor  : { type: String, default: "#fff", },
    // navMenuItems : { type: Array,  required: true   }
  },
  components: {
  },
  computed: {
    getDisplayAddress(){
      return ( this.houseInfo == null
      || this.houseInfo.prefix_address == null
      || this.houseInfo.prefix_address == "") ?
      this.home : this.houseInfo.prefix_address + "/" + this.home;
    }
  },
  methods: {
  },
  created() {
        this.home = $cookies.get("home");
        this.customerName = $cookies.get("customer");
        this.houseInfo = $cookies.get("houseinfo");

        // console.log("xxxx this.roleInfo=",this.roleInfo);
  }
}
</script>
